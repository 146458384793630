.artist-card {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 200px; /* Set a max width for uniformity */
    height: 220px; /* Lock the card height */
    background-color: #f8f9fa; /* Light background */
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center; /* Ensure centered text */
    cursor: pointer;
}

.artist-gallery {
    padding: 40px;
    padding-right: 10vw;
    padding-left: 10vw;
    padding-bottom: 10vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.artist-card img {
    transition: transform 0.3s ease-in-out;
    width: 100%;
    height: 180px; /* Lock image height */
    object-fit: cover; /* Ensures uniformity */
    border-radius: 10px 10px 0 0; /* Rounded only at the top */
}

.artist-card:hover img {
    transform: scale(1.1);
}

.artist-card .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    color: white;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px;
    text-align: center;
}

.artist-card:hover .overlay {
    opacity: 1;
}

/* Artist name styling inside overlay */
.overlay h5 {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 8px;
}

.overlay p {
    font-size: 0.9rem;
    margin: 0;
    line-height: 1.4;
}

/* Ensures artist name remains within fixed space */
.artist-name {
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    height: 40px; /* Locks name section height */
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

/* Responsive grid adjustments */
@media (max-width: 768px) {
    .artist-card {
        max-width: 160px; /* Adjust for smaller screens */
        height: 250px;
    }

    .artist-card img {
        height: 150px;
    }

    .artist-gallery {
        padding: 20px;
    }
}

@media (max-width: 480px) {
    .artist-card {
        max-width: 140px;
        height: 230px;
    }

    .artist-card img {
        height: 130px;
    }

    .artist-name {
        font-size: 0.9rem;
    }
}

.artist-gallery-container {
    text-align: center;
    padding: 20px;
}

.gallery-title {
    font-size: 2rem;
    margin-bottom: 10px;
}

.search-bar {
    width: 100%; /* Make it as wide as its parent */
    max-width: none; /* Remove the width cap */
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 20px;
}

/* Improve search bar on focus */
.search-bar:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}
