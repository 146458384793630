.sidebar {
    width: 200px;
    background-color: #f4f4f4;
    padding: 15px;
    font-size: 13px;
    height: 100vh;
    float: left;
    text-align: center;
}


.sidebar ul li {
    padding: 10px;
    cursor: pointer;
    text-align: center;
    
}

.sidebar-active a {
    color:#ff4172 !important; /* Highlight active link */
    font-weight: bold !important;
}

.sidebar ul {
    list-style: none;
    padding: 0;
}

/* Remove focus outline for keyboard navigation */
.sidebar ul li:focus-visible {
    outline: none; /* Remove outline on focus-visible for keyboard navigation */
}

.sidebar ul li a {
    color: black; /* Default color for links */
}

.sidebar ul li a:hover,
.sidebar ul li a:focus,
.sidebar ul li a:active {
    color: #e64545; /* Change color for active and hover links */
}