/* General layout and content */
.metrics-content {
  margin-left: 200px;
  padding: 10px;
  width: calc(100% - 200px);
  min-height: 100vh;
  background-color: #fff;
}

/* Header section styling */
.header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.report-tabs {
  display:flex;
  gap: 10px;
  margin-bottom: 10px;
}

.header .btn.tab-btn
{
  border-radius: 6px;
  font-size: 15px;

}

.header .btn.tab-btn:hover {
  background-color: #ff4172; /* Change to your preferred hover color */
  color: white;
}

.header .btn.tab-btn.active {
  background-color: #ff4172 !important; /* Highlighted color */
  color: white;
  border-color: #ff4172;
}

.report-divider 
{ 
  height: 1px;
  margin: 10px 0;
  width: 100%;
  margin-top: 18px;
}

.color-box {
  width: 30px;
  height: 30px;
  margin-right: 15px;
  margin-bottom: -5px;
  border: 1px solid #000;
  border-radius: 4px;
  display: inline-block; 
  align-items: center;
}

.icon-button {
  background: none;
  border: none;
  padding: 0; 
  margin: 0; 
  margin-bottom: 15px;
  gap: 5px;
  cursor: pointer; 
  display: flex; 
  align-items: center;
  justify-content: center;
}

.header h6 {
  margin-bottom: 0;
}


.report-header {
  display: flex;
  margin-left: 30px;
  margin-bottom: -15px;
  align-items: center;
}

label 
{
  margin-bottom: 5px;
}

.container {
  display: flex;
  align-items: center;
  gap: 10px; 
  margin-bottom: 10px;
  margin-left: 0px;
}

/* Hover effect for dropdown and date filter */
.date-filter:hover,
.dropdown-toggle:hover {
  border: 1px solid #ff4172;
  background-color: #f8f8f8;
  transform: translateY(-2px);
}

.date-filters {
  display: flex;
  gap: 10px; /* Ensures space between From and To inputs */
  align-items: center; /* Aligns the labels and inputs vertically */
  margin-bottom: 10px;
}

.date-label {
  margin-right: 5px;
  font-size: 14px;
  font-weight: bold;
  display: inline-block;
}

.date-filter {
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.dropdown-toggle {
  cursor: pointer;
  height: 40px;  /* Sets a consistent height */
  padding: 8px;
  border: 1px solid #ccc;
  font-size: 16px;
  border-radius: 8px;
  background-color: white;
  color: black;
  transition: background-color 0.3s, transform 0.2s;
  display: flex;
  align-items: center;  
  justify-content: space-between; 
}

#category-dropdown:hover, #category-dropdown:focus {
  background-color: white !important;
  border-color: #ff4172;
  color: black;
}

.btn.show {
  background-color: transparent !important;
  border-color: #ccc !important;       
  color: black !important;             
}

.dropdown-item {
  font-size: 14px;
  padding: 8px 15px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dropdown-item:hover {
  background-color: #f8f8f8;
}

/* Thickness Buttons*/
.thickness-select{
  padding: 8px 15px;
}

.thickness-button-group {
  display: flex;
  gap: 5px;
}

.thickness-button {
  border: 1px solid #ccc;
  background-color: transparent;
  font-size: 16px;
  color: black;
  transition: all 0.2s ease; /* Smooth transition for all properties */
  transform: scale(1); /* Default state */
  border-radius: 8px;
  padding: 5px 10px; /* Optional: Adjust padding to add more space inside buttons */
  transform-origin: center; /* Scale from the center */
}

/* Selected button styles */
.thickness-button.selected {
  border: 2px solid #ff4172;
}

/* Hover effect for the button */
.thickness-button:hover {
  border-color:#ff4172;
  background-color: #f8f8f8;
  color: black;
  transform: translateY(-2px);
}

/*Product ID box styling */
.autocomplete-box {
  width: 250px;
  padding-left: 8px;
  margin-bottom: 15px;
}

.autocomplete-box .MuiOutlinedInput-root {
  height: 50px;
  border-radius: 8px;
  display: flex;
}

.autocomplete-box .MuiOutlinedInput-root input {
  padding: 0px !important;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 16px;
}

.autocomplete-box .MuiOutlinedInput-root fieldset {
  border-color: #ccc;
  border-width: 1px;
}

.autocomplete-box .MuiOutlinedInput-root:hover fieldset {
  border-color: #ff4172;
  border-width: 1px;
}

.autocomplete-box .MuiOutlinedInput-root.Mui-focused fieldset {
  border-color: #ff4172;
  border-width: 1px;
}

/* Button container styling */
.button-container {
  display: flex;
  gap: 10px; /* Adds space between the buttons */
}


/* Table styling for metrics */
.report-container {
  width:100%;
  height:100%;
  margin-top: 20px;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.chart-container{
  margin-top:0px;
  margin-left: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start; 
}

.metrics-row{
  display:flex;
}

.table-container{
  width:50%;
  max-height: 400px;
  overflow-y: scroll;
}

.metrics-table {
  width: 100%;
  border-collapse: separate;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow-y: scroll;
  table-layout: fixed;
}

.metrics-table th,
.metrics-table td {
  text-align: center;
  padding: 15px;
  border: none;
}

.metrics-table th {
  background-color: #f8f8f8;
  color: #333;
  font-weight: bold;
}

.total-table {
  width: 50%;
  margin-top: 20px;
  border-collapse: separate;
  font-size: 18px;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  table-layout: fixed;
}

.total-table th,td {
  text-align: center;
  padding: 15px;
  border: none;
}

/* Reset Button */
.reset-btn {
  background: transparent;
  border: none;
  font-size: 20px;
  color: #333;
  cursor: pointer;
}

.reset-btn:hover {
  color:black;
  transform: rotate(180deg);
}
.reset-category-btn {
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
}
.reset-category-btn:hover {
  color: #ff4172;
}

