
.materialIcon {
    color: rgb(70, 70, 70);
}

.active {
    border: 2px solid rgba(24, 24, 24, 0.733);
    background: rgba(17, 17, 17, 0.336) !important;
}

.m-n1 {
    margin-right: -0.3rem !important;
    margin-left: -0.3rem !important;
}


/*----------------Image Section Styling----------------*/

.imageUploadBar {
    width: 60%;
}

/*----------------Frame Section Styling----------------*/

.frame-icons {
    width: 2.9vw;
    height: 2.9vw;
}

.frame-grid  {
    border: 2px solid rgba(158, 158, 158, 0.637);
    border-radius: 8px;
}

.frame-grid:hover {
    border: 2px solid rgba(35, 35, 35, 0.937);
    background: rgba(81, 81, 81, 0.278);
}

.frame-grid:active {
    border: 2px solid rgba(11, 11, 11, 0.945);
    background: rgba(0, 0, 0, 0.911) !important;
}

.frame-grid:disabled {
    opacity: 0.42;
    cursor: not-allowed;
    border: 2px solid rgba(169, 169, 169, 0.295);
}


/*----------------Matboard Section Styling----------------*/

.circle {
    display: flex;
    width: 3vw;
    height: 3vw;
    background-color: green;
    border-radius: 50%;
}

.matboardFormLength {
    width: 55%;
}

.matboardFormLength-Number {
    width: 30%;
}


/*----------------Accessories Section Styling----------------*/

.hangingStyleCloseupImage {
    width: 2rem;
    height: 2rem;
}

.hangingStyleFullImage {
    width: 20vw;
    height: 20vw;
}


/*----------------Tool Preview Styling----------------*/

.toolPreviewVisToolWrapper {
    position: relative; /* Ensures child absolute elements are positioned relative to this div */
    display: inline-block; /* Keeps wrapper size close to image size */
    min-width: 800px;
}

.toolPreviewVisTool {
    position:absolute;
    min-width: 25%;
    justify-content: center;
    height: auto;
    width:auto;
}

.backgroundContainer {
    position: relative;
    width: 100%;
    max-width: 800px;
    height: auto;
}

.backgroundImage {
    width: 100%;
    height: auto;
    display: block;
}

.buttonWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
}

.VisToolStaticImageButtons {
    position: absolute;
    display: flex;
    top: 600px;
    height: 50px;
    width: 50px;
    justify-content: center;
    gap:20px;
    width: 100%;
}

.tabPanelButton {
    position: relative;
    height: 100px;
    width: 100px;
    border-radius: 5px;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);
}

.previewToolButtonImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}



/*-------------------------- Samsung S21 Viewport(360x800) --------------------------*/
@media screen and (max-width: 820px) {

    .m-n1 {
        margin-right: -0.3rem !important;
        margin-left: -0.3rem !important;
    }

    /*----------------Image Section Styling----------------*/

    .imageUploadBar {
        width: 98%;
    }

    /*----------------Frame Section Styling----------------*/
        
    .frame-icons {
        width: 10vw;
        height: 10vw;
    }

    .circle {
        display: flex;
        width: 3vw;
        height: 3vw;
        background-color: green;
        border-radius: 50%;
    }

    .frame-grid  {
        border: 2px solid rgba(158, 158, 158, 0.637);
        border-radius: 8px;
    }

    .frame-grid:hover {
        border: 2px solid rgba(35, 35, 35, 0.937);
        background: rgba(81, 81, 81, 0.278);
    }

    .frame-grid:active {
        border: 2px solid rgba(11, 11, 11, 0.945);
        background: rgba(0, 0, 0, 0.911) !important;
    }

    .frame-grid:disabled {
        opacity: 0.42;
        cursor: not-allowed;
        border: 2px solid rgba(169, 169, 169, 0.295);
    }

    .active {
        border: 2px solid rgba(24, 24, 24, 0.733);
        background: rgba(17, 17, 17, 0.336) !important;
    }

    /*----------------Matboard Section Styling----------------*/

    .matboardFormLength {
        width: 100%;
    }

    .matboardFormLength-Number {
        width: 65%;
    }

    /*----------------Accessories Section Styling----------------*/

    .hangingStyleCloseupImage {
        width: 1rem;
        height: 1rem;
    }

    .hangingStyleFullImage {
        width: 12vw;
        height: 12vw;
    }

    /*----------------Tool Preview Styling----------------*/

    .toolPreviewVisTool {
        width: 90vw;
    }

}
/* Override MUI TabPanel padding */
.css-13xfq8m-MuiTabPanel-root {
    padding: 0 !important;
  }
  
  .review-panel {
    padding: 15px;
    font-family: monospace;
    width: 100%;
    box-sizing: border-box;
  }
  
  .product-section {
    margin-bottom: 10px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 5px;
    width: 100%;
  }
  
  .section-title {
    font-size: 14px;
    font-weight: bold;
    color: #ff4172;
    margin-bottom: 5px;
    text-transform: uppercase;
    width: 100%;
  }
  
  .detail-row {
    display: flex;
    margin-bottom: 2px;
    line-height: 1.2;
    width: 100%;
  }
  
  .detail-label {
    width: 30%;
    font-weight: normal;
  }
  
  .detail-value {
    width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .price-value {
    width: 20%;
    text-align: right;
    font-weight: bold;
    color: #ff4172;
  }
  
  .matboard-layer, .matboard-price {
    padding: 0;
    line-height: 1.2;
  }
  
  .total-section {
    margin-top: 10px;
    padding-top: 5px;
    width: 100%;
  }
  
  .total-price {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .total-label {
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  .total-value {
    font-size: 14px;
    font-weight: bold;
    color: #ff4172;
  }
  
  .button-container {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    width: 100%;
  }
  
  .back-button, .cart-button {
    padding: 8px 15px;
    border: none;
    font-weight: bold;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 12px;
  }
  
  .back-button {
    background-color: #333;
    color: white;
  }
  
  .cart-button {
    background-color: #ff4172;
    color: white;
  }
  
  @media (max-width: 576px) {
    .detail-row {
      flex-wrap: wrap;
    }
    
    .detail-label {
      width: 40%;
    }
    
    .detail-value {
      width: 60%;
    }
    
    .price-value {
      width: 100%;
      text-align: left;
      padding-left: 40%;
    }
  }