/* account.css */

/* Container for the entire account page */
.tabsContainer {
    display: flex;
    flex-direction: row;
    height: 100%;
    padding: 20px;
}

/* Tab list container */
.tabsList {
    width: 20%;
    border-right: 2px solid transparent; /* Initially transparent */
}

/* Content container for tab panels */
.tabsContent {
    width: 75%;
}

/* Styling for the Accordion component */
.MuiAccordion-root {
    margin-bottom: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    border: 2px solid transparent; /* Initially transparent */
}

/* Styling for Accordion summary (header) */
.MuiAccordionSummary-root {
    background-color: #f5f5f5;
    padding: 10px 20px;
    border-bottom: 2px solid transparent; /* Initially transparent */
    font-weight: 600;
}

/* When the Accordion is hovered or expanded, add a pink border around it */
.MuiAccordion-root:hover, 
.MuiAccordion-root.Mui-expanded {
    border: 2px solid  #ff4172; /* Pink outer border when hovered or expanded */
}

/* When the Accordion summary is hovered, add a pink bottom border */
.MuiAccordionSummary-root:hover {
    background-color: #e8e8e8;
}

/* Styling for the Accordion details section */
.MuiAccordionDetails-root {
    padding: 20px;
    background-color: #fafafa;
    border-top: 2px solid transparent; /* Initially transparent */
}

/* Content inside the expanded accordion */
.MuiAccordionDetails-root h4 {
    margin-top: 0;
    font-size: 18px;
    font-weight: 600;
}

.MuiAccordionDetails-root p {
    margin: 10px 0;
    font-size: 14px;
    color: #555;
}

/* Styling for the frame image inside the Accordion */
.MuiAccordionDetails-root img {
    max-width: 100%;
    max-height: 300px;
}


/* Adjusting the width and spacing of the tabs on the left */
.MuiTab-root {
    font-size: 16px;
    color: #555; /* Default text color */
}

/* Styling for selected (active) tab */
.MuiTab-root.Mui-selected {
    color:  #ff4172 !important; /* Text color pink when selected */
}

/* Styling for tab hover */
.MuiTab-root:hover {
    color:  #ff4172 !important; /* Text color pink on hover */
}

/* Make tabs vertically aligned */
.MuiTabList-root {
    display: flex;
    flex-direction: column;
}

/* Styling for the order details section */
.orderDetails {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 10px;
    border-radius: 8px;
    max-height: calc(100vh - 200px); 
    overflow-y: auto;
}

.productRow{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 10px;
    padding: 10px 0;
}

/* Styling for order information */
.orderInfo {
    flex: 1; 
    margin-right: 20px; 
}

/* Styling for the price breakdown */
.orderPrice {
    flex: 1;
    margin-right: 20px;
}

/* Styling for the image */
.orderImage {
    flex: 1;
    max-width: 300px;
    margin-right: 40px;
}

/* Footer styling */
footer {
    margin-top: auto;
    padding: 20px;
    background-color: #f1f1f1;
    text-align: center;
}

/* Styling for the Tabs indicator */
.MuiTabs-indicator {
    background-color: #ff4172 !important;
}

/* Remove top padding in Tab Panels */
.css-13xfq8m-MuiTabPanel-root {
    padding-top: 0 !important;
}


.total{
    margin-right: 30px;
}

.total-divider {
    height: 1px;
    margin: 10px 0;
    width: 100%;
    margin-top: 18px;
}

.accordion-summary {
    display: flex;
    width: 100%;
    gap: 150px;
    font-family: Arial, sans-serif;
    align-items: center;
  }
  
  .accordion-summary .frame-id {
    font-size: 1.1em;
    font-weight: bold;
    max-width: 300px; /* Adjust the width as needed */
    white-space: normal; /* Ensure the text can wrap */
    word-wrap: break-word;
    flex: 1;
  }

  .accordion-summary .order-placed
  {
    text-align: center;
  }
  
  .accordion-summary .order-placed,
  .accordion-summary .total {
    font-size: 1em;
    color: grey;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    text-align: center;
  }
  