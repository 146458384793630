/* General Page Layout */
.order-history-page {
    display: flex;
    min-height: 100vh;
    background-color: #fff;
}

/* Main Content */
.order-history-content {
    padding: 10px;
    width: calc(100% - 200px);
    min-height: 100vh;
    background-color: #fff;
}

/* Typography */
h4 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 12px;
    color: #333;
}

/* Header Section */
.header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    gap: 10px;
}

/* Dropdown & Date Filter Styling */
.header .btn,
.header .date-filter,
.header .dropdown-toggle {
    height: 40px;
    padding: 8px;
    border: 1px solid #ccc;
    font-size: 12px;
    border-radius: 0;
    background-color: white;
    color: black;
    transition: background-color 0.3s, transform 0.2s;
    display: flex;
    align-items: center;
}

.date-filter:hover,
.dropdown-toggle:hover {
    border: 1px solid #ff4172;
    background-color: #f8f8f8;
    transform: translateY(-2px);
}

.date-filters {
    margin-top: 10px;
    display: flex;
    gap: 10px;
    align-items: center;
}

.date-label {
    margin-right: 5px;
    font-size: 12px;
    font-weight: bold;
    display: inline-block;
}

.date-filter {
    padding: 8px;
    font-size: 12px;
    border: 1px solid #ccc;
    border-radius: 0;
}

.dropdown-toggle {
    cursor: pointer;
}

/* Dropdown Menu */
.dropdown-menu {
    min-width: 120px;
    padding: 0.25rem 0;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 0.25rem;
}

.dropdown-item {
    font-size: 12px;
    padding: 8px 15px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.dropdown-item:hover {
    background-color: #f8f8f8;
}

/* Custom active state - remove gray background */
.dropdown-item.active, 
.dropdown-item:active {
    background-color: transparent !important;
    color: #ff4172 !important;
    font-weight: bold;
    border: none !important;
}

/* Search Box */
.search-box {
    width: 200px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 0;
    margin-left: auto;
}

/* Button Container */
.button-container {
    display: flex;
    gap: 10px;
}

/* Orders Table */
.order-table-container {
    margin-top: 20px;
}

.order-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    font-family: Arial, sans-serif;
    font-size: 12px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.order-table th {
    background-color: #f8f8f8;
    color: #6c757d;
    font-weight: bold;
    padding: 15px;
    text-align: left;
    border-bottom: 2px solid #ddd;
}

.order-table td {
    text-align: left;
    padding: 15px;
    border-bottom: 1px solid #eee;
}

/* Alternating row colors */
.order-table tbody tr:nth-child(even):not(.price-breakdown-row) {
    background-color: #f9f9f9;
}

.order-table tbody tr:nth-child(odd):not(.price-breakdown-row) {
    background-color: #ffffff;
}

.order-table tbody tr:hover:not(.price-breakdown-row) {
    background-color: #f1f1f1;
    outline: 2px solid #ff4172;
    position: relative;
    z-index: 1;
}

/* Pagination */
.pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.page-btn {
    background-color: #6c757d;
    border: none;
    padding: 10px;
    border-radius: 5px;
    font-size: 14px;
    color: white;
    cursor: pointer;
    transition: background-color 0.2s;
}

.page-btn-secondary {
    background-color: #6c757d;
    border: none;
    padding: 8px 20px;
    border-radius: 5px;
    font-size: 14px;
}

.page-btn-primary {
    background-color: #ff4172;
    border: none;
    padding: 8px 20px;
    border-radius: 5px;
    font-size: 14px;
}

.page-btn:hover,
.page-btn-primary:hover {
    background-color: #ff4172;
}

.page-btn:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

/* Category Filter */
.category-filter {
    display: flex;
    align-items: center;
    gap: 10px;
}

/* Reset Button */
.reset-btn {
    background: transparent;
    border: none;
    font-size: 20px;
    color: #333;
    cursor: pointer;
}

.reset-btn:hover {
    background-color: #ddd;
    transform: rotate(180deg);
}

.reset-category-btn {
    background: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

.reset-category-btn:hover {
    color: #ff4172;
}

/* Status Badge Styling */
.status-badge {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
    border-radius: 15px;
    font-size: 0.85rem;
    font-weight: 500;
    border: none;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    min-width: 120px;
    white-space: nowrap;
    text-align: center;
    text-decoration: none !important; /* Remove underline */
    color: white;
}

.status-badge:hover,
.status-badge:focus {
    opacity: 0.9;
    text-decoration: none !important; /* Ensure no underline on hover/focus */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.status-badge:focus {
    outline: none;
}

/* Status colors */
.status-pending {
    background-color: rgb(68, 67, 67); /* Grey */
}

.status-shipped {
    background-color: #ffc107; /* Yellow */
}

.status-delivered {
    background-color: #28a745; /* Green */
}

.status-cancelled {
    background-color: #dc3545; /* Red */
}

/* Fix for bootstrap's default styling */
.btn-link.dropdown-toggle {
    text-decoration: none !important;
}

/* Override Bootstrap's default focus styling */
.btn-link:focus, 
.btn-link.focus {
    text-decoration: none !important;
    box-shadow: none !important;
}

/* Expand/Collapse functionality */
.expand-arrow {
    width: 30px;
    cursor: pointer;
    text-align: center;
}

.expand-icon {
    display: inline-block;
    transition: transform 0.2s;
    font-size: 14px;
}

.expanded-row {
    background-color: #f5f5f5 !important;
    outline: 2px solid #ff4172;
    position: relative;
    z-index: 1;
}

.price-breakdown-row {
    background-color: #f5f5f5 !important;
    outline: 2px solid #ff4172;
    position: relative;
    z-index: 1;
}

.price-breakdown {
    padding: 15px;
}

/* Breakdown table styles */
.breakdown-table {
    width: 100%;
    margin: 10px auto;
    border-collapse: collapse;
    border: transparent;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.breakdown-table th, 
.breakdown-table td {
    padding: 10px;
    text-align: left;
    border: transparent; /* Changed from 1px solid #ddd to transparent */
}
/* Alternating row colors for breakdown table */
.breakdown-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.breakdown-table tr:nth-child(odd) {
    background-color: #ffffff;
}

.breakdown-table th {
    background-color: #f0f0f0;
    color: #333;
}

.total-row {
    background-color: #e9e9e9 !important;
    color: #ff4172;
    font-weight: bold;
}

.header-row th {
    color: #ff4172;
    background-color: #f0f0f0;
    border-bottom: 2px solid #ff4172;
}

/* Animation for status updates */
@keyframes pulse {
    0% { opacity: 1; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
}

.dropdown-toggle:disabled {
    animation: pulse 1s infinite;
    cursor: not-allowed;
}

/* Loading and error states */
.loading-text,
.error-text,
.no-data-text {
    text-align: center;
    padding: 20px;
    font-size: 16px;
    color: #6c757d;
}

.error-text {
    color: #dc3545;
}

/* Order details flexbox layout */
.order-details-flex {
    display: flex;
    align-items: flex-start;
    gap: 20px;
}
  
/* Price breakdown styling */
.price-breakdown {
    flex: 1;
    min-width: 300px;
}
  
/* Product preview styling */
.product-preview {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
  
/* Make the preview scale to match table height */
.product-preview > div {
    height: 50%;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
  
/* Ensure the tool preview fits within the container */
.product-preview img {
    max-height: 50%;
    max-width: 50%;
    align-items: center;
}
  
/* Responsive adjustments */
@media (max-width: 768px) {
    .order-details-flex {
        flex-direction: column;
    }
    
    .product-preview {
        max-height: 250px;
        margin-top: 20px;
    }
}

/* Make sure inner cell borders remain unchanged */
.order-table td, 
.order-table th {
    text-align: left;
    padding: 15px;
    border-bottom: 1px solid #eee;
}



/* Table section headers */
.table-section-header {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: #ff4172;
    margin: 20px 0 10px 0;
    padding-bottom: 5px;
    border-bottom: 2px solid #f0f0f0;
}

/* Add these to your existing orderHistory.css file */

/* For the pink subtotal/total styling */
.subtotal-row strong {
    color: #ff4172;
  }
  
  .order-total h4 {
    color: #ff4172;
  }
  
  /* Optional additional styling you might want to add */
  product-divider {
    margin: 20px 0;
    
  }
  
  .product-breakdown {
    margin-bottom: 20px;
  }
  
  .breakdown-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .breakdown-table th,
  .breakdown-table td {
    padding: 8px;
    border-bottom: 1px solid #eee;
  }
  
  .header-row {
    background-color: #f9f9f9;
  }
  
  .subtotal-row td,
  .product-total-row td {
    border-top: 2px solid #ddd;
  }
  
  .order-total {
    margin-top: 20px;
    text-align: right;
    padding-right: 20px;
  }

  hr {
    border: none;
    border-top: 1px solid #ddd;
    margin: 20px 0;
    background-color: transparent;
  }
/* Product details styling */
.product-details-container {
    display: flex;
    margin: 15px 0;
    flex-wrap: wrap;
}
    
.product-image-container {
    flex: 0 0 220px;
    margin-right: 20px;
    margin-bottom: 15px;
}
    
.pricing-section {
    flex: 1;
    min-width: 300px;
}
      
.product-image {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 4px;
    border: none; /* Explicitly removed border */
}
      
.no-image {
    width: 200px;
    height: 150px;
    border: none; /* Explicitly removed border */
    display: flex;
    align-items: center;
    justify-content: center;
    color: #999;
    background-color: #f5f5f5;
    border-radius: 4px;
}
    
.download-btn {
    display: block;
    margin-top: 10px;
    padding: 6px 12px;
    background-color: #ff4172;
    ; /* Changed to pink as requested */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    width: 100%;
    max-width: 200px;
    text-align: center;
}
    
.download-btn:hover {
    background-color: ff4172; /* Also changed hover state to pink */
}
    
.product-header {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
}
    
.product-header h4 {
    margin-right: 15px;
}
    
.product-dimensions {
    color: #666;
    font-size: 14px;
    margin-right: 15px;
}
    
/* Enhance the product breakdown display */
.product-breakdown {
    background-color: #f9f9f9;
    padding: 15px;
    margin-bottom: 20px;
}
    
/* Responsive adjustments */
@media (max-width: 768px) {
    .product-details-container {
        flex-direction: column;
    }
        
    .product-image-container {
        margin-right: 0;
        margin-bottom: 20px;
    }
}