.product-page {
    display: flex;
    height: 100vh;
  }
  
  .product-content {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    flex-wrap: wrap;
  }

  .header-btns{
    gap: 15px;
    margin-right: 15px;
    display:flex;
  }
  
  .category-tabs {
    margin-right: auto;
  }
  
  .product-content .search-box {
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 250px;
  }
  
  .product-table-container {
    display: block;
    width: 100%;
    min-width: 50%;
    max-width: 100%;
    overflow-x: auto;
    margin-bottom: 20px;
  }

  .product-table {
    width: 100%;
    font-size: 14px;
    border-radius: 8px;
    overflow: hidden;
    border-collapse: collapse;
    table-layout: auto;
  }
  .product-table th,
  .product-table td {
    text-align: center;
    vertical-align: middle; 
    padding: 10px;
  }

  .product-table.hanging
  {
    width: 50%;
    font-size: 16px;
  }

  .by-size-table{
    width: 90%;
    font-size: 18px;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    border-collapse: separate;
    border-spacing: 0;
    justify-content: center; 
    align-items: center; 
    table-layout: fixed;
    overflow-x: auto;
  }

  .by-size-table th {
    background-color: #f8f8f8;
    color: #6c757d;
    font-weight: bold;
    padding: 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

  .by-size-table th,
  .by-size-table td {
    text-align: center;
    vertical-align: middle; 
    padding: 10px;
  }

  .expand-arrow {
    cursor: pointer;
    text-align: center;
    width: 5%;
  }
  
  .expand-icon {
    display: inline-block;
    transition: transform 0.3s ease;
  }

  .by-size-table .expanded-row {
    background-color: #f5f5f5;
    outline: 1px solid #ff4172;
    
  }
  
  .by-size-table .dropdown-content {
    background-color: #f5f5f5;
  }
  
  .size-chart {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 18px;
    transition: transform 0.3s ease-in-out;
    transform-origin: left;
  }
  
  .size-chart th, .size-chart td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: center;
    white-space: nowrap; 
  }

  .size-chart.large {
    transform: scale(1); 
  }
  
  .size-chart.small {
    transform: scale(0.81); 
  }

  .price-input{
    font-size: 1.1rem; 
    width: 90px;
    text-align: center;
  }

  .price-modal{
    max-width: 90%;
    width: 90%;
    height: 80vh;
  }

  
  .product-image {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 4px;
  }
  
  .icon-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
  }
  
  .edit-icon {
    color: #007bff;
  }
  
  .delete-icon {
    color: #dc3545;
  }
  
  .pagination {
    display: flex;
    justify-content: center !important;
    align-items: center;
    margin-top: 20px;
  }
  
  .page-btn {
    background-color: #ff4172;
    color: white;
    border: none;
    padding: 8px 16px;
    margin: 0 10px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .page-btn:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  /* Modal styles */
  .placeholder-product {
    width: 200px;
    height: 200px;
    border: 2px dashed #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    background-color: #f8f9fa;
  }
  
  .admin-divider {
    margin: 10px 0;
    border-top: 1px solid #ddd;
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .header {
      flex-direction: column;
      align-items: flex-start;
    }
    
    .search-box {
      margin-top: 10px;
      width: 100%;
    }
    
    .category-tabs {
      margin-bottom: 10px;
      width: 100%;
    }
    
    .add-btn {
      margin: 10px 0;
    }
  }

  /* Category Boxes Styling */
.category-boxes {
    display: flex;
    gap: 10px;
  }
  
  .category-box {
    height: 40px;
    width: 90px;
    padding: 8px 20px;
    border: 1px solid #ccc;
    font-size: 14px;
    border-radius: 6px;
    background-color: white;
    color: black;
    transition: background-color 0.3s, transform 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
  }
  
  .category-box:hover {
    background-color: #ff4172;
    color: white;
    cursor:pointer;
    transform: translateY(-2px);
  }
  
  .category-box.active {
    background-color: #ff4172 !important;
    color: white;
    border: 1px solid #ff4172;
  }

  .bg-secondary {
    --bs-bg-opacity: 1;
    background-color: #ff4172 !important;
}

.form-check-input:checked {
  background-color: #ff4172;
  border-color: #ff4172;
}