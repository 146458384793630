body.modal-open {
	overflow: hidden !important;
  }

.modal-body {
	overflow-y: auto;
	max-height: 500px;
	
  }

  .modal-header {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
  }
  
  .modal-title {
	flex: 1;
	text-align: center;
	font-size: 35px; 
  }

.placeholder
{
	width: 150px;
	height: 150px;
	background-color: #f0f0f0;
	margin-bottom: 10px;
	border-radius: 8px;
	cursor:pointer;
	opacity: 1.0;
	object-fit: cover;
	transition: transform 0.3s ease-in-out;
}

.placeholder:hover{
	transform: scale(0.95);
	z-index: 3;
	border: 3px solid #2196F3;
  }

.artist-grid {
	display: flex;
  	flex-wrap: wrap;
	justify-content: flex-start; /* Align items to the left */
	align-content: flex-start; 
	gap: 10px;
}

.icon-button {
    background: none;
    border: none;
    padding: 0; 
    margin: 0; 
    cursor: pointer; 
    display: flex; 
    align-items: center;
    justify-content: center;
  }

  .image-item img {
	transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  	border-radius: 4px; 
	cursor: pointer;
  }
  
  .image-item:hover img {
	transform: scale(0.90);
	z-index: 3;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
	border: 3px solid #2196F3;
  }

  .confirmation-container {
	display: flex;
	align-items: flex-start;
	gap: 30px;
	padding: 20px;
  }
  
  .confirm-image {
	width: 50%;
	max-width: 400px;
	height: auto;
	max-height: 400px;
	object-fit: cover;
	border-radius: 8px;
  }
  
  .artwork-details {
	width: 35%;
  	display: flex;
  	flex-direction: column;
  	gap: 4px;
  	font-size: 16px;
  }
  
  .artwork-details label {
	display: block;
	margin-bottom: 0px;
	font-size: 16px;
  }

  .title-row {
	display: flex;
	align-items: center;
	gap: 6px; /* Adjust spacing between artwork name and artist name */
  }
  
  .title-row h4 {
	margin: 1;
	font-size: 1.5rem;
	white-space: nowrap; /* Prevent text wrapping */
  	overflow: hidden;
	max-width: 100%;
  }
  
  .artwork-details .artist-name {
	font-size: 0.9rem;
	font-weight: 400;
	color: rgb(78, 77, 77); /* Adjust color to differentiate artist name */
	width: 40%;
  }
  
  

  
  
