@import url('https://fonts.cdnfonts.com/css/tw-cen-mt-std');
@import url('https://fonts.cdnfonts.com/css/tw-cen-mt');
@import 'bootstrap/dist/css/bootstrap.css';

/*-----------------Global Rules-----------------*/

* {
  font-family: Century Gothic, sans-serif;
}
a {
  text-decoration: none;
}
.bold {
  font-weight: bold;
}
.font-1 {
  font-size: 15px;
}
.font-1-5{
  font-size: 16px;
}
.font-2 {
  font-size: 20px;
}
.font-3 {
  font-size: 25px;
}
.font-4 {
  font-size: 30px;
}
.font-5 {
  font-size: 35px;
}
.trans {
  transition-duration: 0.3s;
}

.text-on-image {
  position: absolute;
  right: 10%;
  left: 10%;
  bottom: 70%;
}

.text-shadow {
  text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.5);
}

.head-text {
  position: relative;
}

.fixed-position {
  position: fixed;
}

.loadingWrapper {
  border-radius: 22px;
  width: 15vw;
  background-color: #ffffff;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

/*--Global Widths--*/
.w7 {
  width: 7%;
}
.w10 {
  width: 10%;
}
.w20 {
  width: 20%;
}
.w30 {
  width: 30%;
}
.w40 {
  width: 40%;
}
.w50 {
  width: 50%;
}
.w60 {
  width: 60%;
}
.w70 {
  width: 70%;
}
.w80 {
  width: 80%;
}
.w90 {
  width: 90%;
}
.w100 {
  width: 100%;
}

/*-----------------Button Styles-----------------*/

.clear-button {
  border: normal;
  border-radius: 12px;
  border: 2px solid rgb(238, 238, 238);
  color: rgb(238, 238, 238);
  text-align: center;
  transition-duration: 0.3s;
}

.clear-button:hover {
  color: rgb(255, 255, 255);
  border-color: rgb(255, 255, 255);
  background: rgba(255, 255, 255, 0.1);
}

.button {
  border: normal;
  border-radius: 12px;
  border: 2px solid #e6e6e6;
  color: white;
  text-align: center;
  transition-duration: 0.3s;
}

.button:hover {
  color: rgb(58, 58, 58);
  border-color: rgb(58, 58, 58);
}

.button-dark {
  border: normal;
  border-radius: 12px;
  border: 2px solid #5a5a5a;
  color: #5a5a5a;
  text-align: center;
  transition-duration: 0.3s;
}

.button-dark:hover {
  color: rgb(58, 58, 58);
  background: rgba(133, 133, 133, 0.1);
  border-color: rgb(58, 58, 58);
}

/*-----------------NavBar Styles-----------------*/

.NavBarMain {
  margin-left: 0rem;
}

.NavLinkLogo {
  color: #fff;
}

.NavLogo {
  height: 50%;
  width: 50%;
}

.NavLogButton {
  margin-right: 15px;
}

.NavRegisterButton {
  margin-right: 25px;
}
.AdminNav {
  background-color: #0000 !important;
  display: flex;
  justify-content: space-between;
  z-index: 999;
  padding-left: 20px;
}

.admin-divider
{
  height: 1px;
  margin: 10px 0;
  width: 100%;
  margin-top: 18px;
}

.AdminLogoText {
  color: rgb(255, 103, 116);
  margin-left: -5px;
  margin-top: 44px;
}
.AdminLogo{
  margin-left: -80px;
  margin-top: 30px;
}
.Nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  padding: 0.3rem calc((70vw - 1000px) / 2);
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.1);

  /* Third Nav */
  /* justify-content: flex-start; */
}

.NavLink {
  color: #fff;
  font-size: 20px;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
}

.NavLink:hover {
  transition: all 0.3s ease-in-out;
  color: #ff6774;
}

.NavLink:active {
  color: #fff;
}

.Bars {
  display: none;
  color: #fff;
}


.NavMenu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 5px;

  /* Second Nav */
  /* margin-right: 24px; */

  /* Third Nav */
  /* width: 100vw;
  white-space: nowrap; */
}

.NavMenu a {
  margin-left: -40px;
}

.NavBtn {
  display: flex;
  align-items: center;
  margin-right: 20px;

  /* Third Nav */
  /* justify-content: flex-end;
  width: 100vw; */
}

.NavBtnLink {
  border-radius: 9px;
  padding: 8px 2px;
  color: #fff;
  outline: white;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  /* Second Nav */
  margin-left: 12px;
}

.NavBtnLink:hover {
  transition: all 0.2s ease-in-out !important;
  color: #646464 !important;
}

.ShoppingCart {
  transform: scale(1.4);
}

.NavBtnLink.AccountIcon {
  font-size: 45px !important;
  transform: scale(1.5);
  padding-right: 10px;
}

.AccountIcon-dropdown {
  background: none !important; 
  border: none !important;     
  padding: 8px !important;       
  box-shadow: none !important; 
}

.AccountIcon-dropdown::after {
  content: none;
}

.AccountIcon-dropdown-menu {
    padding: 1px !important;
    margin-top: 10px !important; 
    font-size: 16px !important;
    background-color: #f8f9fa; 
    position: absolute !important;
    left: -30px !important;
    min-width: 50px !important;
}
.AccountIcon-dropdown-menu .dropdown-item:hover {
  background-color: transparent;
  color: #ff6774; 
  cursor: pointer; 
}

/*-----------------Page Header Style-----------------*/

.SplashHeader {
  margin-top: 0rem;
  padding-top: 13rem;
  width: 100%;
  height: 44rem;
  background-attachment: fixed; /*----Parallax----*/
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.SubHeader {
  margin-top: 0px;
  padding-top: 8rem;
  width: 100vw;
  height: 50vh;
  background-attachment: fixed; /*----Parallax----*/
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.SmallestHeader {
  margin-top: 0px;
  padding-top: 4rem;
  width: 100vw;
  height: 5vh;
  background-attachment: fixed; /*----Parallax----*/
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/*-----------------Footer Styles-----------------*/

.footerLogoWrapper {
  width: 10vw;
  height: 10vw;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.footerLogoWrapper img {
  width: 100%;
  height: 100%;
}

.socialIcons {
  color: #ff4172;
}

.SocialIconSpacing {
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}

.SocialIconSize {
  transform: scale(1.1);
}

.footerTitle {
  font-size: 2.5rem;
  font-weight: 600;
}

.footerSubTitle {
  font-size: 1.6rem;
  font-weight: 600;
}

.footerWrapper {
  padding-top: 2vw;
  padding-right: 8vw;
  padding-left: 8vw;
  padding-bottom: 3vw;
}

.Link {
  color: #000000;
  font-size: 20px;
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
}

.Link:hover {
  color: #626262;
}


/*-----------------Header Images-----------------*/

.FrameHeaderImage {
  background-image: url("./images/frame-header.jpg"); 

}

.HomeHeaderImage {
  background-image: url("./images/Homeheader.jpg"); 
  background-attachment: fixed; /*----Parallax----*/
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.ContactHeaderImage {
  background-image: url("./images/contact-header.jpg"); 
}

.SubHeaderImage {
  background-image: url("./images/contact-header.jpg"); 
}

.visToolHeaderImage {
  background-image: url("./images/contact-header.jpg"); 
}

.ArtistHeaderImage {
  background-image: url("./images/artist-bg.jpg");
  background-position: center -250px; 
  background-size: cover;
}

/*-----------------Splash Page Styles-----------------*/

.splashFont {
  font-size: 6rem;
  font-weight: 500;
  color: white;
}

.splashFontSub {
  font-size: 2.1rem;
  color: white;
}

.wrapper {
  padding: 40px;
  padding-right: 23vw;
  padding-left: 23vw;
  padding-bottom: 9vw;
}

/*-----------------Sub Header Page Styles-----------------*/

.subFont {
  font-size: 6rem;
  font-weight: 500;
  color: white;
}

.subHeaderFont {
  font-size: 2.1rem;
  color: white;
  padding-left: 5vw;
  padding-right: 5vw;
}



/*-----------------Light Background Sections-----------------*/

.section-Light {
  background-color: white;
}

.sectionTitle-Light {
  font-size: 4rem;
  font-weight: 800;
  color: black;
}

.sectionFont-Light {
  font-size: 1.2rem;
  font-weight: 600;
  color: black;
}

/*-----------------Dark Background Sections-----------------*/

.section-Dark {
  background-color: #282c34;
}

.sectionTitle-Dark {
  font-size: 4rem;
  font-weight: 800;
  padding-top: 1rem;
  color: white;
}

.sectionFont-Dark {
  font-size: 1.2rem;
  font-weight: 600;
  padding-bottom: 1rem;
  color: white;
}

/*-----------------Images-----------------*/

.image-noMove {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.artistHomeImage {
  width: 220%;
  height: 600px;
}

.artistHomeImage img {
  width: 92%;
  height: 100%;
  object-fit: cover;
}

.artist-bg {
  width: 50%;
  height: 720px;
}

.artist-bg img {
  width: 103%;
  height: 100%;
  object-fit: cover;
}

.artistGrid {
  width: 107%;
  height: 360px;
}

.artistGrid img {
  width: 99%;
  height: 100%;
  object-fit: cover;
}

.loginImage {
  width: 400%;
  height: 100%;
}

/*------------------Desktop login Styles---------------------------*/

.loginWrapper {
  height: 37vw;
  width: 43vw;
}

/*------------------Account Setting Page Styles---------------------------*/

.wrapperPadding {
  padding-left: 14rem;
}

.tabPadding {
  padding-left: 7rem;
}

  /*----------------Checkout Success/Fail Styles----------------*/

.checkout-successFail-wrapper {
  height: 24vw;
}

.checkout-successFail-icon {
  text-align: center;
  padding-top: 2rem;
}

.checkout-successFail-text {
  text-align: center;
  padding-top: 2rem;
  margin-left: 2rem;
  margin-right: 2rem;
}

.checkout-redirect-button {
  text-align: center;
  padding-top: 1.2rem;
}

.checkout-popup-inner {
  position: relative;
  width: 30vw;
  margin: 0 auto;
  height: auto;
  margin-top: calc(107vh - 85vh - 20px);
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
}



/*------------------Remove weird Horizonal Scrolling---------------------------*/


*, *::before, *::after {
  box-sizing: inherit;
}

html {
  /* this makes sure the padding and the border is included in the box sizing */
  box-sizing: border-box;
  overflow-x: hidden;
}

html body {
  padding: 0;
  margin: 0;
  overflow-x: inherit;
}

.App {
  height: 100vh;
  width: 100vw;
  max-width: 900px;
  padding: 1rem;
  background-color: lightpink;
}

.render {
  visibility: hidden;
  opacity: 0;
  transition: all 0.6s ease;
}

.show {
  visibility: visible;
  opacity: 1;
}


/*-------------------------- Samsung S21 Viewport(360x800) --------------------------*/
@media screen and (max-width: 820px) {
  
  /* Smaller by 5px */
  .font-1 {
    font-size: 10px;
  }
  .font-1-5{
    font-size: 14px;
  }
  .font-2 {
    font-size: 15px;
  }
  .font-3 {
    font-size: 20px;
  }
  .font-4 {
    font-size: 25px;
  }
  .font-5 {
    font-size: 30px;
  }

  .text-on-image {
    position: absolute;
    right: 30%;
    left: 10%;
    bottom: 55%;
  }
  
  .head-text {
    position: relative;
  }

  /*-----------------Button Styles-----------------*/

  .clear-button {
    border: normal;
    border-radius: 12px;
    border: 2px solid rgb(238, 238, 238);
    color: rgb(238, 238, 238);
    text-align: center;
    transition-duration: 0.3s;
  }

  .clear-button:hover {
    color: rgb(255, 255, 255);
    border-color: rgb(255, 255, 255);
    background: rgba(255, 255, 255, 0.1);
  }

  .button {
    border: normal;
    border-radius: 12px;
    border: 2px solid #e6e6e6;
    color: white;
    text-align: center;
    transition-duration: 0.3s;
  }

  .button-padding {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .button:hover {
    color: rgb(58, 58, 58);
    border-color: rgb(58, 58, 58);
  }

  .button-dark {
    border: normal;
    border-radius: 12px;
    border: 2px solid #5a5a5a;
    color: #5a5a5a;
    text-align: center;
    transition-duration: 0.3s;
  }

  .button-dark:hover {
    color: rgb(58, 58, 58);
    background: rgba(133, 133, 133, 0.1);
    border-color: rgb(58, 58, 58);
  }

  /*-----------------NavBar Styles-----------------*/

  .navWrapper {
    overflow-y: hidden;
  }

  .NavBarMain {
    position: static;
    margin-left: 1rem;
    margin-top: -0.5rem;
    z-index: 999;
    color: #010606;
    background-color: transparent;
    backdrop-filter: none;
    box-shadow: none;
  }
  
  .NavLogo {
    height: 3rem;
    width: 3rem;
    z-index: 4;
  }

  .NavLogButton {
    margin-right: 0px;
  }

  .NavRegisterButton {
    margin-right: 0px;
  }

  .Nav {
    height: 100px;
  }

  .NavBtn {
    display: grid;
  }

  .Bars {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 2rem;
    z-index: 999;
    cursor: pointer;
  }

  .Close {
    display: block;
    position: absolute;
    top: 0;
    margin-left: 3rem;
    transform: translate(-100%, 75%);
    font-size: 2rem;
    z-index: 999;
    cursor: pointer;
  }

  .NavLinkMobile {
    color: #0f0f0f;
    font-size: 20px;
    text-decoration: none;
    cursor: pointer;
    padding-bottom: 2.5rem;
  }

  .NavLinkMobile:hover {
    transition: all 0.3s ease-in-out;
    color: #ff6774;
  }

  .NavLink:active {
    color: #0f0f0f;
  }

  .NavBtnLink {
    border-radius: 9px;
    padding: 8px 2px;
    color: #0f0f0f;
    outline: rgb(0, 0, 0);
    border: 2px solid #5a5a5a;
    cursor: pointer;
    transition-duration: 0.3s;
    text-decoration: none;

    /* Second Nav */
    margin-left: 12px;

    text-align: center;
  }

  .NavBtnLink:hover {
    transition: all 0.2s ease-in-out;
    color: rgb(58, 58, 58);
    background: rgba(133, 133, 133, 0.76);
    border-color: rgb(58, 58, 58);
  }

  .ShoppingCart {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    padding-top: 1.8rem;
    padding-right: 2.3rem;
  }

  .CartIconSize {
    color: #0f0f0f;
    transform: scale(1.1);
  }

  .NavMobileBG {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgb(255, 255, 255);
    z-index: 9999;
  }

  .mobileWrapper {
    padding-top: 10rem;
    
  }

  /* Hidden Parts for Mobile */ 
  .fontLogo {
    display: none;
  }

  .NavMenu {
    display: none;
  }

  .mobileHidden {
    display: none;
  }

  /*-----------------Page Header Style-----------------*/

  .SplashHeader {
    margin-top: -5.8rem;
    padding-top: 7rem;
    width: 100%;
    height: 29rem;
    background-attachment: scroll; 
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .SubHeader {
    margin-top: -5.8rem;
    padding-top: 5rem;
    width: 100vw;
    height: 40vh;
    background-attachment: scroll; 
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .SmallestHeader {
    margin-top: -5.8rem;
    padding-top: 5rem;
    width: 100vw;
    height: 25vh;
    background-attachment: scroll; 
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  /*-----------------Footer Styles-----------------*/

  .footerLogoWrapper {
    width: 28vw;
    height: 28vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .footerLogoWrapper img {
    width: 100%;
    height: 100%;
  }

  .footerLogoPadding {
    padding-top: 2rem;
  }

  .socialIcons {
    color: #ff4172;
  }

  .SocialIconSize {
    transform: scale(0.8);
  }

  .SocialIconSpacing {
    padding-left: 0.2rem;
    padding-right: 0.2rem;
  }

  .footerTitle {
    font-size: 1.3rem;
    font-weight: 600;
  }

  .footerSubTitle {
    font-size: 1rem;
    font-weight: 600;
  }

  .footerWrapper {
    padding-top: 2vw;
    padding-right: 2vw;
    padding-left: 2vw;
    padding-bottom: 3vw;
  }

  .contactDetails {
    font-size: 10px;
    margin-left: -0.5rem;
    margin-top: -0.4rem;
  }

  /*-----------------Header Images-----------------*/

  .FrameHeaderImage {
    background-image: url("./images/frame-header.jpg");
  }

  .HomeHeaderImage {
    background-image: url("./images/Homeheader.jpg");
  }

  .ContactHeaderImage {
    background-image: url("./images/contact-header.jpg");
  }

  .SubHeaderImage {
    background-image: url("./images/contact-header.jpg");
  }

  .visToolHeaderImage {
    background-image: url("./images/contact-header.jpg");
  }

  .ArtistHeaderImage {
    background-image: url("./images/artist-bg.jpg");
  }

  /*-----------------Splash Page Styles-----------------*/

  .splashFont {
    font-size: 5rem;
    font-weight: 500;
    color: white;
  }

  .splashFontSub {
    font-size: 1.5rem;
    color: white;
  }

  .wrapper {
    padding-top: 5vw;
    padding-right: 12vw;
    padding-left: 12vw;
    padding-bottom: 14vw;
  }

  /*-----------------Sub Header Page Styles-----------------*/

  .subFont {
    font-size: 3rem;
    font-weight: 500;
    color: white;
  }

  .subHeaderFont {
    font-size: 1.3rem;
    color: white;
    padding-left: 5vw;
    padding-right: 5vw;
  }

  /*-----------------Light Background Sections-----------------*/

  .section-Light {
    background-color: white;
  }

  .sectionTitle-Light {
    font-size: 2rem;
    font-weight: 800;
    color: black;
  }

  .sectionFont-Light {
    font-size: 1rem;
    font-weight: 600;
    color: black;
  }

  /*-----------------Dark Background Sections-----------------*/

  .section-Dark {
    background-color: #282c34;
  }

  .sectionTitle-Dark {
    font-size: 1.2rem;
    font-weight: 800;
    padding-top: 1rem;
    color: white;
  }

  .sectionFont-Dark {
    font-size: 0.7rem;
    font-weight: 600;
    color: white;
    padding-bottom: 1rem;
  }

  /*-----------------Images-----------------*/

  .image-noMove {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .artistHomeImage {
    width: 216%;
    height: 210px;
  }

  .artistHomeImage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .artist-bg {
    width: 216%;
    height: 210px;
  }
  
  .artist-bg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .artistGrid {
    width: 86vw;
    height: 175px;
  }

  .artistGrid img {
    width: 62%;
    height: 100%;
    object-fit: cover;
  }

  @keyframes inAnimation {
    0% {
      opacity: 0;
      visibility: hidden;
    }
    100% {
      opacity: 1;
      visibility: visible;
    }
  }
  @keyframes outAnimation {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      visibility: hidden;
    }
  }
}






/* For Tablets */
@media screen and (min-width: 540px) and (max-width: 780px) {
  /* .view {
      width: 600px;
  } */
}
