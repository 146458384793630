
.user-content {
  margin-left: 200px;
  padding: 10px;
  width: calc(100% - 200px);
  min-height: 100vh;
  background-color: #fff;
}

/* Header section */
.header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 10px;
}

.header .btn {
  background-color: white;
  color: black;
  border: none;
  border-radius: 2px;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  border: 1px solid #ccc;
}

.header .btn:hover {
  background-color: #ff4172;
  transform: translateY(-2px);}

.search-box {
  width: 200px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-left: auto;
}

.user-table-container {
  margin-top: 5px;
}


.user-table {
  width: 100%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
  font-size: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
}

.user-table th,
.user-table td {
    text-align: center;
    vertical-align: middle;
    padding: 15px;
}

.user-table th {
  background-color: #f8f8f8;
  color: #333;
  font-weight: bold;}

.user-table tbody tr:hover {
  background-color: #f1f1f1;
}

/* General content styling */
.user-content {
  margin-left: 200px;
  padding: 10px;
  width: calc(100% - 200px);
  min-height: 100vh;
  background-color: #fff;
}

/* Header section styling */
.header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 10px;
}

.header .btn {
  background-color: white;
  color: black;
  border: none;
  border-radius: 2px;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  border: 1px solid #ccc;
}

.header .btn:hover {
  background-color: #ff4172;
  transform: translateY(-2px);
}

.search-box {
  width: 200px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Table container and general table styling */
.table-container {
  margin-top: 5px;
}

.table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
}

.table th,
.table td {
  text-align: center;
  vertical-align: middle;
  padding: 15px;
  border: none;
}

.table th {
  background-color: #f8f8f8;
  color: #6c757d;
  font-weight: bold;
}

.table tbody tr:hover {
  background-color: #f1f1f1;
}

/* Pagination controls */
.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.page-btn {
  background-color: #6c757d;
}

.page-btn:hover {
  background-color: #ff4172;
}

.page-btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
