@import url('https://fonts.cdnfonts.com/css/tw-cen-mt-std');
@import url('https://fonts.cdnfonts.com/css/tw-cen-mt');
@import 'bootstrap/dist/css/bootstrap.css';

a {
    color: black;
    text-decoration: none;
  }
hr {
    border: none;
    height: 2px;
    background-color: black;
    margin: 20px 0;
    width: 80%;
    margin: auto;
    margin-top: 50px;
}

.DarkerNavBar {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    z-index: 0;
    background-color: rgba(0, 0, 0, 0.3);
}
.BackButton {
    position: relative;
    margin-top: 50px;
    margin-left: 100px;
    font-size: 15px;
    cursor: pointer;
    width: 10%;
}
.BackButton:hover {
    transition: all 0.3s ease-in-out;
    color: #ff6774;
  }
.BackButton:active {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.3);
}
.ArtistName {
    position: relative;
    margin-top: 20px;
    margin-left: 200px;
    font-size: 40px;
}
.ArtistDescription {
    position: relative;
    width: 40%;
 }
.ArtistProfilePictureContainer {
    position: relative;
    margin-left: 300px;
}
.ArtistProfilePicture {
    width: 250px;
    height: 250px;
    object-fit: cover;
    border-radius: 60%; 
}
.ArtistBioContent {
    position: relative;
    margin-left: 200px;
    display: flex;
}
.ArtworkTitle {
    position: relative;
    margin-top: 20px;
    font-size: 40px;
}
.NumberOfArtworksTitle {
    position: relative;
    margin-top: 10px;
}
.ArtPiece {
    width: 100%;
    height: 270px;
    object-fit: cover;
    border-radius: 10px 10px 0 0; 
}
.ArtWorkCubicle {
    position:relative;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 300px; 
    height: 320px; 
    overflow: hidden;
    background-color: #f8f9fa;
    align-items: center;
    text-align: center; 
    cursor: pointer;
}
.ArtWorkCubicle:hover {
    transition: transform 0.3s ease-in-out;
    transform: scale(1.1);
}
.ArtWorkCubicle:hover .overlay {
    opacity: 1;
}
.ArtWorkCubicle .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    color: white;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px;
    text-align: center;
}
.overlay p {
    font-size: 1.9rem;
    margin: 0;
    line-height: 1.4;
}
.ArtWorkGrid {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
}
.ArtWorkDisplay {
    margin-left: 200px;
    margin-right: 200px;
}
.ArtInfo {
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    padding-top: 10px;
}
.ArtWorkProfile {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}
.ArtWorkProfile .overlay {
    background: white;
    height: 600px;
    max-width: 1000px;
    width: 80%;
    padding: 50px;
    border-radius: 10px;
    display: flex;
}
.ArtWorkProfile .ArtImage {
    height: 100%;
    width: 100%;
    max-height: 700px;
    max-width: 500px;
    object-fit: contain;
    border-radius: 10px; 
}
.vertical-line {
    width: 3px;
    height: 100%;
    margin-left: 50px;
    margin-right: 50px;
    background-color: rgb(131, 131, 131);
    border-radius: 10px;
}
.ArtworkInfo {
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-weight: bold;
    font-size: 1.2rem;
    width:100%;
}
.ArtworkInfo hr {
    height: 2px;
    width: 100%;
    background-color: black;
    margin: 10px 0;
}
.ArtworkSubInfo {
    font-weight: normal;
    font-size: 1rem;
}





