
.artist-page {
    display: flex;
  }
  
  .artist-content {
    width: calc(100% - 200px);
    margin: 10px;
  }

  h3
{
  font-size: 16px;
  font-family: Century Gothic, sans-serif;
  color: #da375f;
  font-weight: 500;
  margin: 10px;
}
  
  .header .btn-container {
    display: flex;
    gap: 50px;
  }
  
  .header .search-box {
    margin-left: auto;
    width: 200px;
    font-size: 12px;
    border: 1px solid #ccc;
    border-radius: 2px;
  }
  
  .header {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .artist-content .header .btn {
    background-color: white;
    color: black;
    border: none;
    border-radius: 6px;
    font-size: 12px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
    border: 1px solid #ccc;
  }
  
  .header .btn:hover {
    background-color: #e64545;
    transform: translateY(-2px);
  }

  .header .btn.active
  {
    background-color: #ff4172 !important;
    color: white;
    border: 1px solid #ff4172;
  }

  .search-box {
    padding: 5px;
    font-size: 12px;
    border: 1px solid #ccc;
    border-radius: 2px;
  }
  
  .artist-table-container {
    margin-top: 10px;
  }
  
  table.artist-table {
    width: 100%;
    font-size: 14px;
    border: 1px solid #ddd;
    border-collapse: collapse;
    border-radius: 8px;
    overflow: hidden;
    table-layout: auto;
  }
  
  .artist-table th,
  .artist-table td {
    text-align: center;
    vertical-align: middle; 
    padding: 15px;
  }
  
  .artist-table th {
    background-color: #f8f8f8;
    font-weight: bold;
  }
  
  .icon-button {
    background: none;
    border: none;
    padding: 0; 
    margin: 0; 
    cursor: pointer; 
    display: flex; 
    align-items: center;
    justify-content: center;
  }
  
  .edit-icon {
    font-size: 24px;
    color: black; 
  }

  .delete-icon {
    font-size: 24px;
    color: black; 
  }

  .pagination {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
  }
  
  .page-btn {
    padding: 5px 10px;
    font-size: 12px;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .page-btn:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  

  .form-row
  {
    display: flex;
    gap: 10px;
  }

  .form-control
  {
    font-size: medium;
  }

  .form-group
  {
    padding-bottom: 10px;
  }

  .btn-secondary 
  {
    border: 1px solid #9d9d9d;
    border: none;
    padding: 8px 20px;
    border-radius: 5px;
    font-size: 16px;
  }
  .btn-primary 
  {
    background-color: #ff4172;
    border: none;
    padding: 8px 20px;
    border-radius: 5px;
    font-size: 16px;
  }
  .btn-primary:hover {
    background-color: #da375f;
    color: white;
}

.placeholder-pfp
{
  width: auto; /* Fixed width */
  height: auto; /* Fixed height */
  min-width: 200px;
  min-height: 200px;
  max-width: 400px;
  max-height: 300px;
  background-color: #f0f0f0; /* Light gray background for placeholder */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1px; 
  overflow: hidden; 
  margin-bottom: 10px;
}

.placeholder-artwork
{
  width: auto; 
  height: auto; 
  min-width: 300px;
  min-height: 200px;
  max-width: 400px;
  max-height: 400px;
  background-color: #f0f0f0; /* Light gray background for placeholder */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1px; 
  overflow: hidden; 
  margin-bottom: 10px;
}

.artwork-image {
  width: 100%;
  height: auto;
  max-width: 300px; /* Adjust the maximum width as needed */
  border-radius: 8px; /* Optional: adds rounded corners to the image */
  object-fit: cover; /* Ensures the image fills the space without distortion */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional: adds shadow around the image */
}

.artist-section{
  width: 100%;
  align-content: center;
  border-collapse: separate;
  font-family: Arial, sans-serif;
  border: 1px solid #da375f;
  border-radius: 8px;
  overflow: hidden;
  margin: 10px;
}

/* Centering the artwork data inside the table */
.artwork-profile-content table {
  width: 100%;
  text-align: center; /* Aligns text to the center */
}

.artwork-profile-content th, .artwork-profile-content td {
  padding: 15px; /* Add space inside cells for better readability */
}

.artwork-image {
  max-width: 100px; /* Adjust image size */
  height: auto;
  margin: 0 auto; /* Center the image inside the cell */
}

.artwork-profile-content .artwork-name,
.artwork-profile-content .artwork-price,
.artwork-profile-content .artwork-description {
  text-align: center; /* Center text in these cells */
}

.artwork-profile-content table {
  display: table;
}

.artwork-profile-content td,
.artwork-profile-content th {
  font-size: 12px;
  font-family: Century Gothic, sans-serif;
  vertical-align: middle; 
}

